import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { connect } from "react-redux";
import { trackingGA } from "@merchx-v2/helpers";
import { useSiteMetadata } from "hooks/use-site-metadata";
import { navigate } from "utils/navigate";
import {
  Button,
  Footer,
  Notification,
  PreventDirectLink,
  FacebookPixels,
  UpgradePageItem,
  SEO,
} from "components";
import cottonImage from "assets/cotton.png";
import everydayImage from "assets/everyday.png";
import lightweightImage from "assets/lightweight.png";
import logoImage from "assets/fmfLogoBlack.png";
import { selectCartId } from "state/HomePage/reducer";
import { selectOrderId } from "state/BillingPage/reducer";
import * as actions from "state/UpgradePage/actions";
import { selectors } from "state/UpgradePage/reducer";

import * as styles from "./UpgradePage.module.scss";

// Lets define next page route
let nextPagePath = "/thankyou";

const UpgradePage = ({ cartId, addUpsellToOrder, upgradeState, data }) => {
  // history.listen(listener => {
  //   if (listener.pathname.match(/(product)|(shipping)|(billing)|(upsell)|(sticker)|(upgrade)/)) {
  //     history.goForward()
  //   }
  // })

  const { shared, landing } = useSiteMetadata();

  const campaignName = landing.CAMPAIGN.name;

  let pageTag =
    data.markdownRemark?.frontmatter?.GOOGLE_SALE_CONVERTION_SNIPPET;
  if (pageTag) {
    pageTag = pageTag.replace(/\[%\{script\}%\]/g, "");
    pageTag = pageTag.replace(/\[%\{\/script\}%\]/g, "");
    pageTag = pageTag.replace(/\[%\{script\s/g, "<script ");
  }

  const [notification, showNotification] = useState();

  useEffect(() => {
    trackingGA();
    const script = document.createElement("script");
    script.text = pageTag;

    if (pageTag) {
      document.head.appendChild(script);
    }
    return () => {
      if (pageTag) {
        document.head.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    showNotification(upgradeState.UIState.error);
  }, [upgradeState.UIState.error]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickValidate = async () => {
    const paid = await addUpsellToOrder();
    if (paid.payload) {
      navigate(nextPagePath);
    }
  };

  return (
    <div className={styles.upgradePage}>
      <PreventDirectLink cartId={cartId} />
      <SEO pathname="/upgrade" />
      <FacebookPixels id={shared.FACEBOOK_PIXEL_ID} event="AddToWishlist" />
      <div className={styles.logoBox}>
        <img className={styles.logo} src={logoImage} alt={campaignName} />
      </div>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>Wait! Your Order Is Not Complete!</p>
          <p className={styles.subtitle}>
            You’ve been selected for our special upgrade!
          </p>
        </div>

        <div className={styles.premiumContainer}>
          <p className={styles.premiumTitle}>
            Upgrade to premium quality for just $5
          </p>

          <UpgradePageItem
            itemIcon={cottonImage}
            itemTitle="Ridiculously Soft Cotton"
            itemText="100% all natural cotton so soft you will want to rub your face"
          />
          <UpgradePageItem
            itemIcon={lightweightImage}
            itemTitle="Ultra Lightweight"
            itemText="Higher thread count with less weight"
          />
          <UpgradePageItem
            itemIcon={everydayImage}
            itemTitle="Extra Durability"
            itemText="Meant for all-day wear, easy to clean and care"
          />

          <p className={styles.premiumFooterText}>Special price: $5</p>
        </div>

        <div className={styles.formDescription}>
          <p className={styles.offerDescription}>
            Make your design extra special by upgrading to premium materials!
          </p>
          <div className={styles.offerDontPass}>
            <p>Don't pass this up!</p>
          </div>

          <div className={styles.button}>
            <Button
              value="Yes, Upgrade Me"
              path={nextPagePath}
              customAction={onClickValidate}
              loading={upgradeState.UIState.isLoading}
            />
          </div>
          <div className={styles.agreeContainer}>
            <div className={styles.agree}>
              By pressing 'Yes, Upgrade Me' you agree to upgrade your design for
              50% off and be charged $5.00 on top of your existing order.
            </div>
          </div>
          <div className={styles.declineOffer}>
            <Link to={nextPagePath} className={styles.declineOfferLink}>
              No thanks, I don't want better merch
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer />
        <Notification message={notification} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartId: selectCartId(state),
  orderId: selectOrderId(state),
  upgradeState: selectors.selectUpgradeState(state),
});

const mapDispatchToProps = {
  addUpsellToOrder: actions.addUpsellToOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePage);

export const query = graphql`
  query UpggradePage {
    markdownRemark(fields: { slug: { eq: "/upgrade/" } }) {
      frontmatter {
        GOOGLE_SALE_CONVERTION_SNIPPET
      }
    }
  }
`;
