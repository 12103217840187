// extracted by mini-css-extract-plugin
export var upgradePage = "UpgradePage-module--upgradePage--2jX3n";
export var logoBox = "UpgradePage-module--logoBox--EVsXw";
export var logo = "UpgradePage-module--logo--jXrtQ";
export var content = "UpgradePage-module--content--SCehm";
export var titleContainer = "UpgradePage-module--titleContainer--3jMfG";
export var title = "UpgradePage-module--title--1NmPi";
export var subtitle = "UpgradePage-module--subtitle--2Z5OO";
export var premiumContainer = "UpgradePage-module--premiumContainer--3rCA7";
export var premiumTitle = "UpgradePage-module--premiumTitle--1AlDZ";
export var premiumFooterText = "UpgradePage-module--premiumFooterText--ik53-";
export var formDescription = "UpgradePage-module--formDescription--ZdFED";
export var offerDescription = "UpgradePage-module--offerDescription--3JAYC";
export var offerDontPass = "UpgradePage-module--offerDontPass--2G5h8";
export var button = "UpgradePage-module--button--26Db1";
export var agreeContainer = "UpgradePage-module--agreeContainer--1cOx5";
export var agree = "UpgradePage-module--agree--1dcy6";
export var declineOffer = "UpgradePage-module--declineOffer--1Viqk";
export var declineOfferLink = "UpgradePage-module--declineOfferLink--1lLWW";
export var footerContainer = "UpgradePage-module--footerContainer--1_ZgB";